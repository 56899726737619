import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Button } from 'react-bootstrap'
import Layout from "../components/Layout"
import Sidebar from '../components/Sidebar'
import SEO from "../components/seo"
import { SlugLink } from '../components/utils'
import moment from "moment";
const baseuri = "https://dmf5.xyz"
class BlogPostTemplate extends React.Component {

  render() {
    const post = this.props.data.privateGraphql.getnew
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteUrl = this.props.data.site.siteMetadata.siteUrl
    const Author = this.props.data.privateGraphql.getauthorbynewslugtitle;
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.title}
          description={post.short}
          image={`${post.image}`}
          isBlogPost={true}
          datePublished={post.publishDate}
          authorName={post.profileName}
          authorUrl={`${siteUrl}/authors/${post.profileSlugName}`}
          authorSocial={Author.twitter}
        />
        <div className="row d-flex justify-content-center">
        <article className="sppa col-lg-8 col-md-12">
          <header className="arhd">
            <h1 className="arhl">{post.title}</h1>
            <div className="au_published d-flex align-items-center">
            <div className="tagsc">
              {post.categoryName !== null ?
                post.categoryName.split(',').map(catx => {
                  if (catx !== "") return (
                    <Link to={`/${SlugLink(catx)}`} className="chip" key={catx}>{catx}</Link>
                  )
                })
                :
                null
              }
            </div>
                    <span>•</span>
                    <time>{moment(post.publishDate).format('DD.MM.YYYY')}</time>
            </div>
            <div className="aus_card d-flex justify-content-start align-items-center">
              <div className="au_image-row">
                <div className="au_image-roitem">
                  <img className="au_image" src={Author.image} alt="favicon" />
                </div>
              </div>
              <div className="au_info">
                <span className="au_name">
                  <Link to={`/authors/${Author.slugname}`}>{Author.profilename}</Link>
                </span>
                <span className="au_tw">
                  <a href={`${Author.twitter}`}>
                    Twitter
                  </a>
                </span>

              </div>
            </div>
          </header>
          <div className="tdpc" dangerouslySetInnerHTML={{ __html: post.detail }} />
          <div className="tagsc2">
              {post.tags !== null ?
                post.tags.split(',').map(tagx => {
                  if (tagx !== "") return (
                    <Link to={`/${SlugLink(tagx)}`} className="sppf" key={tagx}>{tagx}</Link>
                  )
                })
                :
                null
              }
            </div>
        </article>
        <Sidebar/>
        </div>
        <div className="post-footer">
          <Row>
            <Col className="item-relate text-left col-lg-6 col-md-6 col-12">
              {previous && (
                <div className="ref-prev">
                  <span>Previous article</span>
                  <Link to={previous.slugtitle} title={previous.title} rel="prev">
                  <div className="featured-thumbnail">
                    <img src={previous.image} title={previous.title} alt={previous.title} />
                  </div>
                  <div className="rlt">
                    {previous.title}
                    </div>
                  </Link>
                </div>
              )}

            </Col>
            <Col className="item-relate text-md-right col-lg-6 col-md-6 col-12">
              {next && (
                <div className="ref-next">
                  <span>Next article</span>
                  <Link to={next.slugtitle} title={next.title} rel="next">
                  <div className="featured-thumbnail">
                    <img src={next.image} alt={next.title} title={next.title} />
                  </div>
                  <div className="rlt">
                    {next.title}
                    </div>
                  </Link>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query ($slug: String = "404",$siteId: Int = 1083) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    privateGraphql{
      getauthorbynewslugtitle(slugtitle: $slug, siteId: $siteId) {
        id
        profilename
        twitter
        slugname
        image
        bio
      }
      getnew(slugtitle: $slug, siteId: $siteId) {
        id
        detail
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
      }
    }
  }
`
